import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';

class Hero extends React.Component {
  render() {
    return (
    <div className="hero is-large background-hero has-text-centered	">
      <Img className="background is-transparent" fluid={ this.props.background } />
          <div className="hero-body">
            <div className="container">
              <h1 className="title is-uppercase is-size-1 ">
              { this.props.headline }
              </h1>
              <h3 className="subtitle">
              { this.props.subhead }
              </h3>
              { this.props.btnTxt &&
                <div className="button is-info">
                  <Link to={this.props.url} alt="framing inquiry" className="has-text-white">
                    {this.props.btnTxt}
                  </Link>
                </div>
              }
            </div>
          </div>
    </div>

    );
  }
}

export default Hero;

